.About-main{
    background-color: #191919;
    width: 100%;
    padding: 20px 60px;
    height: auto;
    justify-content: center;
    align-items: center;
}

.About{
    height: auto;
    width: 100%;
    flex-direction: column; /* Stack items vertically */
    align-items: center;
    display: flex;
   
}

.about-text {
    height: 100%;
   color: white;
        margin-top: 40px;
        text-align: start;
        font-family: 'Poppins', sans-serif;
        margin-top: 20px;


}

.about-text h6 {
   color: white;
        text-align: start;
        font-size: 15px;
        line-height: 25px;
        

}

.about-text h5 {
    color: white;
         text-align: start;
         font-size: 16px;
         line-height: 25px;
         
 
 }


 .about-text h4 {
    color: white;
         text-align: start;
         font-size: 16px;
         line-height: 25px;
         
 
 }

 .about-text img{
    width: 9rem;
  
 }
.about-text p{
   color: white;
        text-align: start;
        font-size: 16px;
        line-height: 25px;


}

.Companies-main{
    background-color: #191919;
    width: 100%;
    padding: 20px 60px;
    height: auto;
    justify-content: center;
    align-items: center;
}

.Companies{
    height: auto;
    width: 100%;
    flex-direction: column; /* Stack items vertically */
    align-items: center;
    display: flex;
   
}

.Companies-text {
    height: 100%;
   color: white;
        margin-top: 40px;
        text-align: start;
        font-family: 'Poppins', sans-serif;
        margin-top: 20px;
        line-height: 20px;
        


}

.Companies-text h1 {
  font-size: 2rem;
  transform: scale(1,1);
  letter-spacing: 5px;
        


}

.Companies-text h6{
    line-height: 30px;
}



@media only screen and (max-width: 768px) {
    .About-main {
        padding: 20px 30px !important;
       
    }
    .about-text p{
        text-align: center !important;

    }

    .Companies-main {
        padding: 20px 30px !important;
       
    }
    .Companies-text p{
        text-align: center !important;

    }
    .about-text h5{
        text-align: center !important;
  
    }


}

.close-icon-container {
    display: flex;
    justify-content: center; /* Center the icon horizontally */
    align-items: center; /* Center the icon vertically */
    margin-top: 30px;
    width: 50px; /* Set width of the circle */
    height: 50px; /* Set height of the circle */
    border-radius: 50%; /* Make it circular */
    cursor: pointer; /* Change cursor to pointer on hover */
    transition: background-color 0.3s;
    position: relative;
    border: 1px solid white; /* Border around the circle */
 
}

.close-icon-container::after
 {
    content: '';
    position: absolute;
    background-color: white; /* Color of the plus sign */
}



.close-icon-container::after {
    width: 15px; /* Reduced length of the vertical line */
    height: 2px; /* Reduced thickness of the vertical line */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center the line */
}


.Gallery-main{
    background-color: #191919;
    width: 100%;
    /* padding: 20px 20px; */
    height: auto;
    justify-content: center;
    align-items: center;
}

.Gallery{
    height: auto;
    width: 100%;
    flex-direction: column; /* Stack items vertically */
    align-items: center;
    display: flex;
   
}

.Gallery-images{
    padding: 10px;
    margin-top: 20px;
    height: auto;
    width: 100%;
}
.single-img {
    width: 100%;
    padding: 10px 0px;
    height: 300px;
}

.single-img img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
}

.bold{
    font-weight: bolder;
    font-style: italic;
    text-align: start;
}



@media only screen and (max-width: 768px) {
    .Gallery-main{
 
        padding: 0px 0px;
      
    }
    
    .about-text p{
        text-align: center !important;

    }
    .about-text h5{
        text-align: center !important;
  
    }


}

.Contact-main{
    background-color: #191919;
    width: 100%;
    padding: 20px 60px;
    height: auto;
    justify-content: center;
    align-items: center;
}

.Contact-text h2{
      margin: 0;
      color: white;
      font-family: oswald-extralight, oswald, sans-serif;
      letter-spacing: 5px;
      line-height: 2.1;
}


.Contact{
    height: auto;
    width: 100%;
    flex-direction: column; /* Stack items vertically */
    align-items: center;
    display: flex;
   
}

.Contact-text {
    height: 100%;
   color: white;
        margin-top: 40px;
        text-align: start;
        font-family: 'Poppins', sans-serif;
        margin-top: 20px;
        line-height: 30px;


}

.Contact-text h6 {
   color: white;
        text-align: start;
        font-size: 15px;
        line-height: 30px;


}



