
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');


/* .Home-heads {
    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
} */

.Home-heads {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
    height: 100vh; 

    color: white;
    font-family: 'Poppins', sans-serif;

    position: relative;
    overflow: hidden; /* Ensures the video doesn't overflow */
    text-align: center;
    color: white;
    padding: 20px;
}





.Home-heads h1 {
    font-size: 1.4rem; 
    margin-top: -10px;
    font-weight: 600; 
    letter-spacing: 1px;
}


.Home-heads img{
    width: 23rem;
}
.main h1 {
    font-size: clamp(4rem, 6vw, 6rem);
           margin: 0; 
    color: white;
    font-family: oswald-extralight, oswald, sans-serif;
    letter-spacing:10px;
    line-height: 2.1;
    font-weight: bolder;
    display: inline-block;
    transform: scale(.5,1);
}


.Home-banner-container {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    padding: 20px; 
    z-index: 1;
    position: relative;
    white-space: nowrap; /* Prevents the text from wrapping to a new line */
    font-size: clamp(4rem, 6vw, 6rem);

    width: 100%;
    height: 90vh; /* Full viewport height */
    overflow: hidden; /* Hide overflow so the video won't extend outside the container */
}

.Home-banner-container video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the video covers the entire container */
    z-index: -1;
    transform: translate(-50%, -50%); /* Center the video */
}



.video-react .video-react-control-bar {
    display: none;
  }

  .video-react .video-react-big-play-button {
    display: none !important; 
}


  .Deahaze-icon {
    position: absolute;
    top: 28px;
    right: 45px;
    cursor: pointer; 
    color: white;
    cursor: pointer;
}

.Top .close-icon-wrapper {
    position: absolute;
    right:80px;
    top: 50px;
    cursor: pointer;

  }
  


  
.About-heads {
    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    flex-direction: column;


}

.Companies-heads {
    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    flex-direction: column;


}

.Projects-heads {
    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    flex-direction: column;


}
.Testimonials-heads {
    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    flex-direction: column;


}

.About-heads h1 {
    
    z-index: 1;

}

.Projects-heads h1 {
    
    z-index: 1;

}
.Companies-heads h1 {
    
    z-index: 1;

}

.Gallery-heads h1 {
    
    z-index: 1;

}
.Instagram-heads h1 {
    
    z-index: 1;

}
.Contact-heads h1 {
    
    z-index: 1;

}

.Home-heads h1 {
    
    z-index: 1;
    font-size: 90px;

}
.Home-heads h3 {
    
    z-index: 1;

}.Home-heads p  {
    
    z-index: 1;
    letter-spacing: 2px;
    font-size: 20px;


}




.About-banner-container {
    background-image: url("../Components/Images/dk10.JPG");
    background-attachment: fixed; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 
    height: 100vh; 
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
        /* opacity: 8.5; */
        position: relative;

}


.About-banner-container:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 0; 
}




.Projects-banner-container {
    background-image: url("../Components/Images/dk12.jpg");
    background-attachment: fixed; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 
    height: 100vh; 
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
        /* opacity: 8.5; */
        position: relative;

}


.Projects-banner-container:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 0; 
}

.Companies-banner-container {
    background-image: url("../Components/Images/dk12.jpg");
    background-attachment: fixed; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 
    height: 100vh; 
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
        /* opacity: 8.5; */
        position: relative;

}


.Companies-banner-container:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 0; 
}

.Home-banner-container:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 0; 
}


.Gallery-banner-container:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 0; 
}

.Instagram-banner-container:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 0; 
}

.Contact-banner-container:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 0;
}



@media only screen and (max-width: 768px) {
    .About-banner-container {
        background-attachment: scroll; 
        background-position: center; 
        background-size: cover; 
        height: 60vh; 
        padding: 25px 0 !important; 
    }
    .Home-heads h1 {
    
        font-size: 46px;
    
    }
    
.Home-heads p  {
        font-size: 14px;
}
}

@media only screen and (max-width: 480px) {
    .About-banner-container {
        background-size: cover; 
        background-position: center; 
        height: auto;
        padding: 10px; 
        text-align: center; 
    }
    .Home-heads p  {
        font-size: 11px;
}

.Home-heads h1 {
    
    font-size: 37px;

}
}


  .Gallery-heads {
    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    flex-direction: column;
}
  .Gallery-banner-container {
    background-image: url("../Components/Images/dk8.jpg");
  
  
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    height: auto;
    padding: 40px;
    opacity: 8.5;
    position: relative;

  }


  .Instagram-heads {
    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    flex-direction: column;

}
  .Instagram-banner-container {
    background-image: url("../Components/Images/dk6.jpg");
    position: relative; 

  
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    padding: 40px;
    opacity: 8.5;
    position: relative;

  }



  .Contact-heads {
    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    flex-direction: column;

}
  .Contact-banner-container {
    background-image: url("../Components/Images/dk9.jpg");
  
  
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
    padding: 40px;
    opacity: 8.5;
    position: relative;

  }
  @media (max-width: 768px) {
    .About-banner-container {
        height: auto; 
        background-size: cover; 
        padding: 25px 0; 
    }

    .About-heads {
        display: flex;
        justify-content: center;
        height: 290px !important;
    }

    .Projects-banner-container {
        height: auto !important; 
        background-size: cover !important;  
        background-attachment: scroll; 
        background-position: center; 
        background-size: cover; 
        height: 100vh; 
        
        padding: 25px 0 !important; 
    }

    .Projects-heads {
        display: flex;
        justify-content: center;
        height: 290px !important;
    }

    .Testimonials-banner-container {
        height: auto; 
        background-size: cover; 
        padding: 25px 0; 
    }

    .Testimonials-heads {
        display: flex;
        justify-content: center;
        height: 290px !important;
    }

    .Companies-banner-container {
        height: auto !important; 
        background-size: cover !important;  
        background-attachment: scroll; 
        background-position: center; 
        background-size: cover; 
        height: 100vh; 
        
        padding: 25px 0 !important;  
    }

    .Companies-heads {
        display: flex;
        justify-content: center;
        height: 290px !important;
    }

    .Home-banner-container {
        height: auto; 
        background-size: cover; 
        font-size: clamp(1rem, 6vw, 2rem);

    }

    .Home-heads {
        display: flex;
        justify-content: center;
        height: 400px;
    }

    .Gallery-banner-container {
        height: auto !important; 
        background-size: cover !important;  
        background-attachment: scroll; 
        background-position: center; 
        background-size: cover; 
        height: 100vh; 
        
        padding: 25px 0 !important; 
    }

    .Gallery-heads {
        display: flex !important;
        justify-content: center !important;
        height: 290px !important;
    }

    .Instagram-banner-container {
        height: auto !important; 
        background-size: cover !important;  
   
        background-attachment: scroll; 
        background-position: center; 
        background-size: cover; 
        height: 60vh; 
        
        padding: 25px 0 !important; 
    }

    .Instagram-heads {
        display: flex !important;
        justify-content: center !important;
        height: 290px !important;
    }
    .Contact-banner-container {
        height: auto !important; 
        background-size: cover !important;  
   
        background-attachment: scroll; 
        background-position: center; 
        background-size: cover; 
        height: 80vh; 
        
        padding: 25px 0 !important; 
    }
    .Contact-heads {
        display: flex !important;
        justify-content: center !important;
        height: 290px !important;
    }
}


.icon-container {
    display: flex;
    justify-content: center; /* Center the icon horizontally */
    align-items: center; /* Center the icon vertically */
    margin-top: 30px;
    width: 50px; /* Set width of the circle */
    height: 50px; /* Set height of the circle */
    border-radius: 50%; /* Make it circular */
    cursor: pointer; /* Change cursor to pointer on hover */
    transition: background-color 0.3s;
    position: relative;
    border: 1px solid white; /* Border around the circle */
}

.icon-container::before,
.icon-container::after {
    content: '';
    position: absolute;
    background-color: white; /* Color of the plus sign */
}

.icon-container::before {
    width: 2px; /* Reduced thickness of the horizontal line */
    height: 15px; /* Reduced length of the horizontal line */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center the line */
}

.icon-container::after {
    width: 15px; /* Reduced length of the vertical line */
    height: 2px; /* Reduced thickness of the vertical line */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center the line */
}


.icon-container:hover {
    background-color: rgba(255, 255, 255, 0.5); /* Change color on hover */
}





.Top{
    position: sticky;
    top: 0;
    z-index: 100;
    position: relative;

}

.Top .navbar {
    height: auto; 
    background-color: #191919;
    font-family: 'Poppins', sans-serif; 
    padding: 75px;
    box-sizing: border-box;
}

@media (max-width: 768px) {
    .Top .navbar {
        height: 100vh; 
        padding: 20px; 
    }


    
}

.Top .navbar .nav-link {
    color: white; 
    font-family: 'Poppins', sans-serif; 
    font-weight: 600; 
    letter-spacing: 9px;
    line-height: 7px;
    font-size: 20px;
    margin-top: 14px;
    display: inline-block;
    transform: scale(.8,1);
}

.Top .navbar .navbar-brand {
    color: white; 
    font-family: 'Poppins', sans-serif; 
    font-weight: 600; 
}


.Testimonials-banner-container {
    height: 60vh; 
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: black;
    overflow: hidden; 
    max-height: 100vh; /* Ensure it doesn't overflow the viewport */
    object-fit: cover; /* Ensures the video covers the entire div */
    border: none; 
}

.Testimonials-carousel {
    width: 100%;
    height: 100vh;

}

.Testimonials-carousel iframe {
    width: 100%; /* Full width */
    height: 100vh; 

}

/* Optional - Carousel styling */
.carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
}



.stay-in-touch {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    text-align: center; 
    font-size: 2rem; 
    white-space: nowrap; 
    width: 100%;
    padding: 20px;
    height: auto;
    background-color: #191919; 
    border-bottom: 1px solid white;
  }
  
  /* Optional: Adjust font size for smaller screens */
  @media (max-width: 600px) {
    .stay-in-touch {
      font-size: 1.5rem; /* Decrease the font size on smaller screens */
    }
  }

  .socials {
    display: flex;
    justify-content: center; 
    text-align: center;
    background-color: #191919; 
    color: white;
    margin: 0 auto; 
    padding: 10px;
  }
  
  .socials p {
    margin: 0 10px; /* Adds space between the social media items */
  }

  .foot{
    background-color: #191919; 
    color: white;
    height: auto;
    overflow: hidden;
  }


  .flash-news-container {
    overflow: hidden; /* Hide overflow to prevent scrollbars */
    position: relative; /* Needed for positioning */
    height: 180px; /* Adjust height as necessary */
    background-color:#191919 ;
}

.flash-news {
    display: flex; /* Use flexbox for layout */
    animation: scroll 13s linear infinite; /* Infinite scrolling */
    width: calc(100% * 3); /* Double the width to hold two sets of logos */
}

.news-item {
    margin-right: 0; /* Remove spacing to eliminate gaps */
}

.brand-logo {
    height: 180px; /* Logo height */
    width: 180px; /* Maintain aspect ratio */
}

/* Keyframes for scrolling animation */
@keyframes scroll {
    0% {
        transform: translateX(0); /* Start from the left edge */
    }
    100% {
        transform: translateX(-50%); /* Move left by half the total width */
    }
}
